const env: string = process.env.NODE_ENV ? process.env.NODE_ENV : 'dev';

const domainMap: any = {
    // 开发环境
    development: {
        eggMall: 'http://www.test.jimuitech.com',
        repair: 'http://my.repair.dev.jimuitech.com',
        scp: 'http://insurance.test.jimuitech.com',
        eggAlai: 'https://seller.test.jimuitech.com',
        supplierCenter: 'http://supplier.test.jimuitech.com'
    },
    dev: {
        eggMall: 'http://www.test.jimuitech.com',
        repair: 'http://my.repair.test.jimuitech.com',
        scp: 'http://insurance.test.jimuitech.com',
        eggAlai: 'https://seller.test.jimuitech.com',
        supplierCenter: 'http://supplier.test.jimuitech.com'
    },
    // 测试环境
    test: {
        eggMall: 'http://www.test.jimuitech.com',
        repair: 'http://my.repair.test.jimuitech.com',
        scp: 'http://insurance.test.jimuitech.com',
        eggAlai: 'https://seller.test.jimuitech.com',
        supplierCenter: 'http://supplier.test.jimuitech.com'
    },
    // uat环境
    uat: {
        eggMall: 'http://www.uat.jimuitech.com',
        repair: 'http://my.repair.uat.jimuitech.com',
        scp: 'http://insurance.UAT.jimuitech.com',
        eggAlai: 'https://seller.uat.jimuitech.com',
        supplierCenter: 'http://supplier.uat.jimuitech.com'
    },
    // stable环境
    stable: {
        eggMall: 'http://www.stable.jimuitech.com',
        repair: 'http://my.repair.stable.jimuitech.com',
        scp: 'http://insurance.stable.jimuitech.com',
        eggAlai: 'https://seller.stable.jimuitech.com',
        supplierCenter: 'https://supplier.stable.jimuitech.com'
    },
    // 线上环境
    production: {
        eggMall: 'https://www.eggmalltech.com',
        repair: 'https://i.egg-repair.com',
        scp: 'http://www.speedclaimparts.com',
        eggAlai: 'https://seller.eggmalltech.com',
        supplierCenter: 'https://supplier.eggmalltech.com'
    }
};

export const domainConfig = domainMap[env];
