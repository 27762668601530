























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import EnglishIcon from '@/assets/images/English.png';
import ThailandIcon from '@/assets/images/Thailand.png';
// import IndonesiaIcon from '@/assets/images/Indonesia.png';
// import PhilippeIcon from '@/assets/images/Philippe.png';
// import MalaysiaIcon from '@/assets/images/Malaysia.png';
// import VietnamIcon from '@/assets/images/Vietnam.png';
// import ChinaIcon from '@/assets/images/China.png';

@Component({
    name: 'LangBox'
})
export default class extends Vue {
    $i18n: any;
    $route: any;
    $router: any;

    currentLang = '';

    get locale() {
        return AppModule.locale;
    }

    get currentIcon() {
        return this.languageList.find((item: any) => item.langCode === this.locale).iconUrl;
    }

    languageList: any = [
        {
            localeLangDesc: 'English',
            langCode: 'en',
            iconUrl: EnglishIcon
        },
        {
            localeLangDesc: 'ไทย',
            langCode: 'th',
            iconUrl: ThailandIcon
        }
        // {
        //     localeLangDesc: 'Indonesia',
        //     langCode: 'id',
        //     iconUrl: IndonesiaIcon
        // },
        // {
        //     localeLangDesc: 'Pilipino',
        //     langCode: 'fil',
        //     iconUrl: PhilippeIcon
        // },
        // {
        //     localeLangDesc: 'Malaysia',
        //     langCode: 'ms',
        //     iconUrl: MalaysiaIcon
        // },
        // {
        //     localeLangDesc: 'Tiếng Việt',
        //     langCode: 'vi',
        //     iconUrl: VietnamIcon
        // },
        // {
        //     localeLangDesc: '简体中文',
        //     langCode: 'zh',
        //     iconUrl: ChinaIcon
        // },
        // {
        //     localeLangDesc: '한국어',
        //     langCode: 'ko',
        //     iconUrl: 'https://image-cdn.eggmalltech.com/flags/Asia-Pacific/%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD.png'
        // }
    ];

    async changeLang(langCode: string) {
        this.$i18n.locale = langCode;
        AppModule.SET_LANGUAGE(langCode);
    }
}
