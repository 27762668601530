































import { Vue, Component } from 'vue-property-decorator';
import LangBox from '@/components/LangBox.vue';

@Component({
    name: 'MainHeader',
    components: {
        LangBox
    }
})
export default class MainHeader extends Vue {
}
