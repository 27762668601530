
















































































import { Vue, Component } from 'vue-property-decorator';
import utils from '@/utils/index';
import { domainConfig } from '@/config';

@Component({
    name: 'Footer'
})
export default class Header extends Vue {

    private openWebsite(type: string, path?: string) {
        window.open(domainConfig[type] + (path || ''));
    }

}
