








import { Vue, Component } from 'vue-property-decorator';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

@Component({
    name: 'MainLayout',
    components: {
        MainHeader,
        MainFooter
    }
})
export default class MainLayout extends Vue {
}
